import { combineReducers } from 'redux';
import basicReducer from './basicReducer';
import userReducer from './userReducers';
import loginReducer from './loginReducer';
import productReducer from './productReducer'
const rootReducer = combineReducers({
  basic:basicReducer,
  user:userReducer,
  login:loginReducer,
  product:productReducer
});

export default rootReducer;
