import {USER_LIST,USER_FILTER,USER_LIST_UPDATE,USER_SELECTED_ITEMS,USER_SELECTED_ITEMS_ADD,USER_TABLE_LOADING,USER_MODAL_VISIBLE,USER_FORM_REF,USER_SAVE_FORM_FIELDS, USER_MENU_KEY,USER_UPDATE, USER_CURRENT_PAGE, USER_TOTAL } from '../constants/actionTypes';
import {notification, Avatar } from 'antd';
import React from 'react';
export const setUserList=value=>{
    return {
        type:USER_LIST,
        value
    }
}
export const setFilterType=value=>{
    return {
        type:USER_FILTER,
        value
    }
}
export const updateUserList=value=>({type:USER_LIST_UPDATE,value});
export const setSelectedItem=value=>({type:USER_SELECTED_ITEMS,value});
export const addSelectedItem=value=>({type:USER_SELECTED_ITEMS_ADD,value});
export const isTableLoading=value=>({type:USER_TABLE_LOADING,value});
export const isModalVisible=value=>({type:USER_MODAL_VISIBLE,value});
export const setFormRef=value=>({type:USER_FORM_REF,value});
export const saveFormFields=(value)=>({type:USER_SAVE_FORM_FIELDS,value})
export const setMenuKey=value=>({type:USER_MENU_KEY,value})
export const update=value=>({type:USER_UPDATE,value});
export const setCurrentPage=value=>({type:USER_CURRENT_PAGE,value});
export const setTotal=value=>({type:USER_TOTAL,value});
export const loadList=(axios,filter,offset,limit)=>{
    return dispatch=>{
        dispatch(isTableLoading(true));
        axios.post("/user/list",{filter,offset,limit}).then(resp=>{
            if(resp.data.status){
                var newArr=resp.data.list.map((elem,i)=>{
                    var {google,facebook,createdOn,blocked,username,email}=elem;
                    var newElem={createdOn,username,email};
                    newElem.key=elem._id;
                    newElem.pic=<Avatar src={elem.dp}/>
                    newElem.google=google ? "Connected":"not Connected";
                    newElem.facebook=facebook ?"Connected":"not Connected";
                    newElem.blocked=blocked ?"Yes":"No";
                    return newElem;
                  });
                dispatch(setTotal(resp.data.total));
                dispatch(setUserList(newArr));
                dispatch(isTableLoading(false));
            }else{
                dispatch(isTableLoading(false));
                notification['error']({
                    message: 'Failed to Load Users\'s list',
                    description:resp.data.msg,
                });
            }
        }
    ).catch(err=>{
        dispatch(isTableLoading(false))
        notification['error']({
            message: 'Failed to Load Users\'s list',
            description:err.message,
        });
    })
}
}
export const submitForm=(reset,axios,values)=>{
    return dispatch=>{
       return  axios.post("/user/add",values)
        .then(resp=>{
            if(resp.data.status){
                reset();
                notification['success']({
                    message: 'User Added Succesfully',
                    description: resp.data.msg,
                });
                dispatch(loadList(axios,"",0,10));
                dispatch(setCurrentPage(1));
            }else if(resp.data.status===false){
                notification['error']({
                    message: 'Failed to Add User',
                    description: resp.data.msg,
                });
            }else{
                notification['error']({
                    message: 'Failed to Add User',
                    description:resp.data,
                });
            }
        }).catch(err=>{
            notification['error']({
                message: 'Failed to Add User',
                description:err.message,
            });
        })
    }
}
export const updateUser=(reset,axios,values,list)=>{
    // console.log("list",list);
    return dispatch=>{
       return  axios.post("/user/update",values)
        .then(resp=>{
            if(resp.data.status){
                reset();
                notification['success']({
                    message: 'User Updated Succesfully',
                    description: resp.data.msg,
                });
                let newList=list.map((el,i)=>{
                    if(el.username===values.username){
                        return {...el,email:values.email}
                    }
                    return el;
                });
                dispatch(setUserList(newList));
            }else if(resp.data.status===false){
                notification['error']({
                    message: 'Failed to Update User',
                    description: resp.data.msg,
                });
            }else{
                notification['error']({
                    message: 'Failed to Update User',
                    description:resp.data,
                });
            }
        }).catch(err=>{
            notification['error']({
                message: 'Failed to Update User',
                description:err.message,
            });
        })
    }
}
export const deleteUser=(axios,username,list)=>(dispatch=>(
    axios.post("/user/delete",{username}).then(resp=>{
        if(resp.data.status){
            let newList=list.filter((value)=>{
                if(value.username!==username)
                    return value;
            });
            dispatch(setUserList(newList));
            notification['success']({
                message: 'Successfully Deleted User',
                description: resp.data.msg,
            });
        }else if(resp.data.status===false){
            notification['error']({
                message: 'Failed to Delete User',
                description: resp.data.msg,
            });
        }else{
            notification['error']({
                message: 'Failed to Delete User',
                description:resp.data,
            });
        }
    }).catch(err=>{
        notification['error']({
            message: 'Failed to Delete User',
            description:err.message,
        });
    })
));
export const suspendUser=(axios,username,list)=>(dispatch=>(
    axios.post("/user/block",{username}).then(resp=>{
        if(resp.data.status){
            let newList=list.map((el,i)=>{
                if(el.username===username){
                    return {...el,blocked:"Yes"}
                }
                return el;
            });
            dispatch(setUserList(newList));
            notification['success']({
                message: 'Successfully Suspended User',
                description: resp.data.msg,
            });
        }else if(resp.data.status===false){
            notification['error']({
                message: 'Failed to Suspend User',
                description: resp.data.msg,
            });
        }else{
            notification['error']({
                message: 'Failed to Suspend User',
                description:resp.data,
            });
        }
    }).catch(err=>{
        notification['error']({
            message: 'Failed to Suspend User',
            description:err.message,
        });
    })
));

export const reActivateUser=(axios,username,list)=>(dispatch=>(
    axios.post("/user/unblock",{username}).then(resp=>{
        if(resp.data.status){
            let newList=list.map((el,i)=>{
                if(el.username===username){
                    return {...el,blocked:"No"}
                }
                return el;
            });
            dispatch(setUserList(newList));
            notification['success']({
                message: 'Successfully Re-activated User',
                description: resp.data.msg,
            });
        }else if(resp.data.status===false){
            notification['error']({
                message: 'Failed to Re-activate User',
                description: resp.data.msg,
            });
        }else{
            notification['error']({
                message: 'Failed to Re-activate User',
                description:resp.data,
            });
        }
    }).catch(err=>{
        notification['error']({
            message: 'Failed to Re-activate User',
            description:err.message,
        });
    })
));
export const search=(axios,value)=>{
    return dispatch=>{
        if(value===""){
            dispatch(loadList(axios,"",0,10))
        }else{
            dispatch(isTableLoading(true));
            return axios.post("/user/search",{value})
            .then(resp=>{
                dispatch(isTableLoading(false));
                if(resp.data.status){
                    var newArr=resp.data.list.map((elem,i)=>{
                        var {google,facebook,createdOn,blocked,username,email}=elem;
                        var newElem={createdOn,username,email};
                        newElem.key=elem._id;
                        newElem.pic=<Avatar src={elem.dp}/>
                        newElem.google=google ? "Connected":"not Connected";
                        newElem.facebook=facebook ?"Connected":"not Connected";
                        newElem.blocked=blocked ?"Yes":"No";
                        return newElem;
                    });
                    dispatch(setTotal(resp.data.total));
                    dispatch(setUserList(newArr));
                    dispatch(isTableLoading(false));
                }else{
                    dispatch(setUserList([]));
                    dispatch(isTableLoading(false));
                }
            }).catch(err=>{
                dispatch(isTableLoading(false));
                notification['error']({
                    message: 'Failed to Search User',
                    description:err.message,
                });
            })
        }
    }
}
export const remove=(axios,values)=>{
    let usernames=values.map((v,i)=>{
        return v.username;
    })
    return dispatch=>{
        dispatch(isTableLoading(true));
        return axios.post("/user/remove",{usernames})
        .then(resp=>{
            dispatch(isTableLoading(false));
            if(resp.data.status){
                dispatch(loadList(axios,"",0,10));
                dispatch(setCurrentPage(1));
            }else{
                notification['error']({
                    message: `Failed to Remove ${usernames.length} Users`,
                    description: resp.data.msg,
                });
            }
        }).catch(err=>{
            dispatch(isTableLoading(false));
            notification['error']({
                message: 'Failed to Remove User(s)',
                description:err.message,
            });
        })
    }
}

export const filter=(axios,filter)=>{
    return dispatch=>{
        dispatch(isTableLoading(true));
        dispatch(setFilterType(filter));
        return axios.post("/user/filter",{filter})
        .then(resp=>{
            dispatch(isTableLoading(false));
            if(resp.data.status){
                
            }else{
                
            }
        }).catch(err=>{
            dispatch(isTableLoading(false));
            notification['error']({
                message: 'Faileed to Filter Users',
                description:err.message,
            });
        })
    }
}