import React from 'react';
import {Layout,Breadcrumb,Table,Menu,Icon,Input, Button, Col,Row,Dropdown,DatePicker,Modal} from 'antd';
const RangePicker=DatePicker.RangePicker;
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions/userActions';
import CollectionCreateForm from './CreateForm';
const confirm = Modal.confirm;
const {Header,Content,Footer}=Layout
const {SubMenu}=Menu;
const MenuItemGroup = Menu.ItemGroup;
const {Search}=Input;

const  onChange=(date,dateString)=>{
    console.log(dateString);
}

class Users extends React.Component{
  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }
  handleCreate=()=>{
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      // console.log('Received values of form: ', values);
      this.props.actions.isModalVisible(false);
      if(!this.props.update)
        this.props.actions.submitForm(form.resetFields,this.props.axios,values);
      else
        this.props.actions.updateUser(form.resetFields,this.props.axios,values,this.props.list);
      
    });
  }
  componentDidMount(){
    if(this.props.list.length===0){
      this.props.actions.loadList(this.props.axios,"",0,10);
    }
  }
  onPageChange =page=>{
    this.props.actions.setCurrentPage(page);
    this.props.actions.loadList(this.props.axios,"",(page-1)*10,10);
  }
  showConfirm=(obj,key)=> {
    const self=this;
    let action="";
    switch(key){
      case "del":
      action="Delete";break;
      case "edit":
      action="Update";break;
      case "block":
      action="Block";break;
      case "unblock":
      action="Unblock";break;
      default:
      action="";
    }
    confirm({
      title: 'Do you Want to '+action+' this User?',
      content: "Username = "+obj.username,
      onOk() {
        switch(key){
          case "del":
            self.props.actions.deleteUser(self.props.axios,obj.username,self.props.list);
            break;
          case "edit":
            // this.props.actions.deleteUser(obj.name);
            self.props.actions.update(true);
            self.props.actions.saveFormFields({
              username:{ value:obj.username},
              email:{value:obj.email},
              password:{value:""},
              password2:{value:""}
            })
            break;
          case "block":
            self.props.actions.suspendUser(self.props.axios,obj.username,self.props.list);
            break;
          case "unblock":
            self.props.actions.reActivateUser(self.props.axios,obj.username,self.props.list);
            break;
          default:
            console.log("dont know ehat to do");

        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  actionClick=(e,props)=>{
      // console.log(e.key)
      this.showConfirm(props,e.key)
      
  }
  removeClick=()=>{
    let self=this;
    confirm({
      title: `Do you Want to remove all selected user(s) in this page`,
      content: `${self.props.selectedItems.length} item(s) selected`,
      onOk() {
        self.props.actions.remove(self.props.axios,self.props.selectedItems);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  
  render(){
    const Mnu =(props)=> (
      <Menu onClick={e=>{this.actionClick(e,props)}}>
        <Menu.Item key="edit"><Icon type="edit" />Update</Menu.Item>
        <Menu.Item key="del"><Icon type="delete" />Delete</Menu.Item>
        {props.blocked==="No" ? <Menu.Item key="block"><Icon type="pause-circle" />Suspend</Menu.Item>:<Menu.Item key="unblock"><Icon type="play-circle" />Re-activate</Menu.Item>}
        
      </Menu>
    );
    const columns = [
        { title: 'DP', dataIndex: 'pic', key: '0',fixed: 'left',width:60 },
        {
          title: 'Username', dataIndex: 'username', key: 'name', key:'1'
        },
        
        { title: 'Email', dataIndex: 'email', key: '2' },
        
        { title: 'Google', dataIndex: 'google', key: '3' },
        { title: 'Facebook', dataIndex: 'facebook', key: '4' },
        { title: 'Suspended', dataIndex: 'blocked', key: '5' },
        { title: 'Joined on', dataIndex: 'createdOn', key: '6' },
        
        // { title: 'Column 6', dataIndex: 'address', key: '6' },
        // { title: 'Column 7', dataIndex: 'address', key: '7' },
        // { title: 'Column 8', dataIndex: 'address', key: '8' },
        {
          title: 'Action',
          key: 'operation',
          fixed: 'right',
          width: 100,
          render: (props) =>{
            // console.log(props);
            return (
                <Dropdown overlay={()=><Mnu {...props}/>}>
                    <Button style={{ marginLeft: 8 }}>
                        <Icon type="bars"/><Icon type="down" />
                        
                    </Button>
                </Dropdown>
          )},
        },
      ];
      
      const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          this.props.actions.setSelectedItem(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
        },
      };
      
    return (
        <>
        <Header style={{ background: '#fff', padding: 0,textAlign:'center' }} >Users</Header>
        <Content style={{ margin: '0 8px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>  
                <Link to="/"><Icon type="dashboard"/> Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/users"><Icon type="user"/> Users</Link></Breadcrumb.Item>
            <Breadcrumb.Item> Free users</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{padding:4,background: '#fff', minHeight: 360 }}>
            <Menu
            onClick={(e)=>{this.props.actions.setMenuKey(e.key)}}
            selectedKeys={this.props.menu_key}
            mode="horizontal"
            // theme={"dark"}
            >
                <Menu.Item key="search">
                    <Search
                    placeholder="input search text"
                    onSearch={value => this.props.actions.search(this.props.axios,value)}
                    style={{ width: 200 }}
                    />
                </Menu.Item>
                <Menu.Item key="range">
                <RangePicker onChange={onChange} style={{maxWidth:240}}/>
                </Menu.Item>
                <SubMenu title={<span className="submenu-title-wrapper"><Icon type="filter" />Filter</span>}>
                <MenuItemGroup title="">
                    <Menu.Item key="setting:0" onClick={()=>this.props.actions.filter(this.props.axios,'all')}>All Users</Menu.Item>
                    <Menu.Item key="setting:1" onClick={()=>this.props.actions.filter(this.props.axios,'new')}>New Users</Menu.Item>
                    <Menu.Item key="setting:2" onClick={()=>this.props.actions.filter(this.props.axios,'blocked')}>Suspended Users</Menu.Item>
                </MenuItemGroup>
                <MenuItemGroup title="Activity">
                    <Menu.Item key="setting:3" onClick={()=>this.props.actions.filter(this.props.axios,'inactive')}>Inactive Users</Menu.Item>
                    <Menu.Item key="setting:4" onClick={()=>this.props.actions.filter(this.props.axios,'active')}>Most Active Users</Menu.Item>
                </MenuItemGroup>
                </SubMenu>
                <Menu.Item key="alipay">
                <Link to="/usersPlus">Premium Users</Link>
                </Menu.Item>
                
                <Menu.Item>
                    <Button onClick={()=>this.props.actions.update(false)}>
                    <Icon type="user-add"/>
                    Create New
                    </Button>
                </Menu.Item>
                
                
            </Menu>
            {this.props.selectedItems.length >0 ?(<Row style={{margin:5}}>
                <Col xs={{span:16,offset:8}} sm={{span:10,offset:14}} md={{span:10,offset:14}} lg={{span:9,offset:17}} xl={{span:6,offset:18}}><span>Selected {this.props.selectedItems.length} items</span> <Button  type="primary" onClick={this.removeClick}>
                <Icon type="user-delete"/>
                Remove
                </Button></Col>
                
            </Row>):null}
            
            <Table loading={this.props.loading} columns={columns} dataSource={this.props.list} scroll={{ x: 1200 }} rowSelection={rowSelection} pagination={{position:'bottom',current:this.props.currentPage,onChange:this.onPageChange,pageSize:10,total:this.props.total}}/>
        </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
            Xynes Admin ©2019 Created by Aman
        </Footer>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.modalVisible}
          onCancel={()=>{this.props.actions.isModalVisible(false);}}
          onCreate={this.handleCreate}
          axios={this.props.axios}
          update={this.props.update}
        />
        </>
    )
  }
}
const mapStateToProps=(state)=>{
  return {...state.basic,...state.user};
}
const mapDispatchToProps=(dispatch)=>{
  return {actions:bindActionCreators(actions,dispatch)}
}
export default connect(mapStateToProps,mapDispatchToProps)(Users);