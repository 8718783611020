import React from 'react';
import {Layout,Breadcrumb,Table,Menu,Icon,Input, Button, Col,Row,Avatar,Dropdown,DatePicker,Modal} from 'antd';
const RangePicker=DatePicker.RangePicker;
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions/productActions';
import CollectionCreateForm from './CreateForm';
const confirm = Modal.confirm;
const {Header,Content,Footer}=Layout
const {SubMenu}=Menu;
const MenuItemGroup = Menu.ItemGroup;
const {Search}=Input;

const  onChange=(date,dateString)=>{
    console.log(dateString);
}

class Products extends React.Component{
  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }
  state={
    fileList:[]
  }
  listUpdate=(value)=>{
    this.setState({fileList:value});
  }
  handleCreate=()=>{
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      // console.log('Received values of form: ', values);
      this.props.actions.isModalVisible(false);
      // form.resetFields();
      if(!this.props.update){
        this.props.actions.submitForm(form.resetFields,this.props.axios,values);
      }else{
        this.props.actions.updateProduct(form.resetFields,this.props.axios,values,this.props.sId,this.props.list);
      }
      
      // this.setState({ visible: false });
    });
  }
  showConfirm=(obj,key)=> {
    const self=this;
    let action="";
    switch(key){
      case "del":
      action="Delete";break;
      case "edit":
      action="Update";break;
      case "block":
      action="Block";break;
      case "unblock":
      action="Unblock";break;
      default:
      action="";
    }
    confirm({
      title: 'Do you Want to '+action+' this Product?',
      content: "Product Name = "+obj.name,
      onOk() {
        switch(key){
          case "del":
            self.props.actions.deleteProduct(self.props.axios,obj._id,self.props.list);
            break;
          case "edit":
            // this.props.actions.deleteProduct(obj.name);
            self.props.actions.selectedId(obj._id);
            self.props.actions.update(true);
            self.setState({fileList:[]});
            self.props.actions.saveFormFields({
              pname:{ value:obj.name},
              desc:{value:obj.desc},
              link:{value:obj.link},
              tags:{value:obj.plainTags},
              uploadName:{value:""}
            })
            break;
          case "block":
            self.props.actions.publishProduct(self.props.axios,obj._id,self.props.list);
            break;
          case "unblock":
            self.props.actions.unPublishProduct(self.props.axios,obj._id,self.props.list);
            break;
          default:
            console.log("dont know ehat to do");

        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  actionClick=(e,props)=>{
      // console.log(e.key)
      this.showConfirm(props,e.key)
      
  }
  removeClick=()=>{
    let self=this;
    confirm({
      title: `Do you Want to remove all selected user(s) in this page`,
      content: `${self.props.selectedItems.length} item(s) selected`,
      onOk() {
        self.props.actions.remove(self.props.axios,self.props.selectedItems);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  componentDidMount(){
    if(this.props.list.length===0){
      this.props.actions.loadList(this.props.axios,"",0,10);
    }
    if(this.props.savedTags.length===0){
      this.props.actions.loadTags(this.props.axios);
    }
  }
  render(){
    const Mnu =(props)=> (
      <Menu onClick={e=>{this.actionClick(e,props)}}>
        <Menu.Item key="edit"><Icon type="edit" />Update</Menu.Item>
        <Menu.Item key="del"><Icon type="delete" />Delete</Menu.Item>
        {props.published==="No" ? <Menu.Item key="block"><Icon type="play-circle" />Publish</Menu.Item>:<Menu.Item key="unblock"><Icon type="pause-circle" />Un-Publish</Menu.Item>}
        
      </Menu>
    );
    const columns = [
        { title: 'Icon', dataIndex: 'icon', key: '1',fixed: 'left',width:60 },
        {
          title: 'Product Name', dataIndex: 'name', key: 'name', 
        },
        
        { title: 'Description', dataIndex: 'desc', key: '2' },
        
        { title: 'Link', dataIndex: 'link', key: '3' },
        { title: 'Tags', dataIndex: 'tags', key: '4' },
        { title: 'Published', dataIndex: 'published', key: '5' },
        { title: 'Created On', dataIndex: 'createdOn', key: '6' },
        // { title: 'Column 7', dataIndex: 'address', key: '7' },
        // { title: 'Column 8', dataIndex: 'address', key: '8' },
        {
          title: 'Action',
          key: 'operation',
          fixed: 'right',
          width: 100,
          render: (props) =>{
            // console.log(props);
            return (
                <Dropdown overlay={()=><Mnu {...props}/>}>
                    <Button style={{ marginLeft: 8 }}>
                        <Icon type="bars"/><Icon type="down" />
                        
                    </Button>
                </Dropdown>
          )},
        },
      ];
      
      const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          this.props.actions.setSelectedItem(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
        },
      };
      
    return (
        <>
        <Header style={{ background: '#fff', padding: 0,textAlign:'center' }} >Products</Header>
        <Content style={{ margin: '0 8px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>  
                <Link to="/"><Icon type="dashboard"/> Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item><Icon type="user"/> Products</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{padding:4,background: '#fff', minHeight: 360 }}>
            <Menu
            onClick={(e)=>{this.props.actions.setMenuKey(e.key)}}
            selectedKeys={this.props.menu_key}
            mode="horizontal"
            // theme={"dark"}
            >
                <Menu.Item key="search">
                    <Search
                    placeholder="input search text"
                    onSearch={value => this.props.actions.search(this.props.axios,value)}
                    style={{ width: 200 }}
                    />
                </Menu.Item>
                <Menu.Item key="range">
                <RangePicker onChange={onChange} style={{maxWidth:240}}/>
                </Menu.Item>
                <SubMenu title={<span className="submenu-title-wrapper"><Icon type="filter" />Filter</span>}>
                <MenuItemGroup title="">
                    <Menu.Item key="setting:0" onClick={()=>this.props.actions.filter(this.props.axios,'all')}>All Products</Menu.Item>
                    <Menu.Item key="setting:1" onClick={()=>this.props.actions.filter(this.props.axios,'new')}>New Products</Menu.Item>
                    <Menu.Item key="setting:2" onClick={()=>this.props.actions.filter(this.props.axios,'unpublised')}>Unpublished Products</Menu.Item>
                </MenuItemGroup>
                <MenuItemGroup title="Activity">
                    <Menu.Item key="setting:3" onClick={()=>this.props.actions.filter(this.props.axios,'inactive')}>Unpopular Products</Menu.Item>
                    <Menu.Item key="setting:4" onClick={()=>this.props.actions.filter(this.props.axios,'active')}>Most Popular Products</Menu.Item>
                </MenuItemGroup>
                </SubMenu>
                
                <Menu.Item>
                    <Button onClick={()=>{this.props.actions.update(false);}}>
                    <Icon type="plus"/>
                    Create New
                    </Button>
                </Menu.Item>
                
                
            </Menu>
            {this.props.selectedItems.length >0 ?(<Row style={{margin:5}}>
                <Col xs={{span:16,offset:8}} sm={{span:10,offset:14}} md={{span:10,offset:14}} lg={{span:9,offset:17}} xl={{span:6,offset:18}}><span>Selected {this.props.selectedItems.length} items</span> <Button  type="primary" onClick={this.removeClick}>
                <Icon type="delete"/>
                Remove
                </Button></Col>
                
            </Row>):null}
            
            <Table loading={this.props.loading} columns={columns} dataSource={this.props.list} scroll={{ x: 1900 }} rowSelection={rowSelection} />
        </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
            Xynes Admin ©2019 Created by Aman
        </Footer>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.modalVisible}
          onCancel={()=>{this.props.actions.isModalVisible(false);}}
          onCreate={this.handleCreate}
          axios={this.props.axios}
          update={this.props.update}
          fileList={this.state.fileList}
          listUpdate={this.listUpdate}
        />
        </>
    )
  }
}
const mapStateToProps=(state)=>{
  return {...state.basic,...state.product};
}
const mapDispatchToProps=(dispatch)=>{
  return {actions:bindActionCreators(actions,dispatch)}
}
export default connect(mapStateToProps,mapDispatchToProps)(Products);
