import { LOG_IN,UPDATE_AXIOS} from '../constants/actionTypes';
export const login=value=>{
    return {
        type:LOG_IN,
        value
    }
}
export const axiosUpdate=(value)=>{
    return {
        type:UPDATE_AXIOS,
        value
    }
}