import React from 'react';
import {Avatar} from 'antd';
import axios from 'axios';
import {api} from '../conf';
import * as logo from '../images/logo.png';
const instance = axios.create({
  baseURL: api,
  timeout: 5000,
  headers: {'Authorization': 'Bearer ' + localStorage.getItem("adminToken")}
});
export default {
  basic:{
    auth: localStorage.getItem("adminToken")===null ? false:true,
    axios:instance
  },
  users:{
    currentPage:1,
    total:0,
    list:[],
    filter:"",
    loading:false,
    selectedItems:[],
    modalVisible:false,
    username:{
      value:""
    },
    email:{
      value:''
    },
    password:{
      value:''
    },
    password2:{
      value:''
    },
    menu_key:[],
    update:false
  },
  products:{
    currentPage:1,
    total:0,
    list:[],
    filter:"",
    loading:false,
    selectedItems:[],
    modalVisible:false,
    menu_key:[],
    pname:{value:""},
    desc:{value:""},
    link:{value:""},
    upload:{value:[]},
    tags:{value:[]},
    uploadName:{value:''},
    savedTags:[],
    update:false
  }
};
