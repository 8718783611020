import React from 'react';
import {
     Modal, Form, Input,
  } from 'antd';
import {connect} from 'react-redux';
import { saveFormFields} from '../../actions/userActions';
    // eslint-disable-next-line
class XX extends React.Component {
    handleConfirmPassword = (rule, value, callback) => {
      const { getFieldValue } = this.props.form
      if (value !== getFieldValue('password')) {
          callback('Passwords do not match')
      }
        callback()
    }
    handleUsername=(rule,value,callback)=>{
      if(!this.props.update){
      if(value){this.props.axios.post('user/usernameValidation',{value}).
      then(resp=>{
        if(resp.data.status){
          callback("Username Already Exists");
        }else{
          callback();
        }
      }).catch(err=>{
        callback();
      });
    }else{
      callback();
    }
    }else{
      callback()
    }
    }
    handleEmail=(rule,value,callback)=>{
      if(value){this.props.axios.post('user/emailValidation',{value}).
      then(resp=>{
        if(resp.data.status){
          callback("Email Already Exists");
        }else{
          callback();
        }
      }).catch(err=>{
        callback();
      });
    }else{
      callback();
    }
    }
    render() {
    const {
        visible, onCancel, onCreate, form,
    } = this.props;
    const { getFieldDecorator } = form;
    
    return (
        <Modal
        visible={visible}
        title="Create a new collection"
        okText="Create"
        onCancel={onCancel}
        onOk={onCreate}
        centered
        bodyStyle={{height:300,overflowY:'scroll'}}
        >
        <Form layout="vertical">
            <Form.Item label="Username" >
            {getFieldDecorator('username', {
                rules: [{ required: true, message: 'Please input Username!' },{
                  validator:this.handleUsername
                }],
            })(
                <Input disabled={this.props.update}/>
            )}
            </Form.Item>
            <Form.Item label="Email">
            {getFieldDecorator('email',{
                rules: [{ required: true, message: 'Please enter an email!' },{type:'email',message: 'Please enter a valid email!'},{
                  validator:this.handleEmail
                }],

            })(<Input />)}
            </Form.Item>
            <Form.Item label={this.props.update ? "Password(leave empty to keep the old password":"Passoword"}>
            {getFieldDecorator('password',{
                rules: [{ required: !this.props.update, message: 'Please enter a password!' },{min:6,message: 'Password should be minimum 6 character long'}],

            })(<Input type="password" />)}
            </Form.Item>
            <Form.Item label={this.props.update ? "Confirm Password(leave empty to keep the old password":"Confirm Passoword"}>
            {getFieldDecorator('password2',{
                rules: [{ required: !this.props.update, message: 'Please re-enter password!' },{validator:this.handleConfirmPassword}],

            })(<Input type="password" />)}
            </Form.Item>
        </Form>
        </Modal>
    );
    }
}
const CollectionCreateForm = Form.create({
    name: 'add_users',
    onFieldsChange(props, changedFields) {
        props.saveFormFields(changedFields);
      },
    mapPropsToFields(props) {
      return {
        username: Form.createFormField(props.formState.username),
        password: Form.createFormField(props.formState.password),
        email:Form.createFormField(props.formState.email),
        password2:Form.createFormField(props.formState.password2)
      };
    },
    onValuesChange(_, values) {
      // console.log(values);
    }, 
})(XX)
export default connect((state) => {
    return {
      formState: {
        username: state.user.username,
        email:state.user.email,
        password:state.user.password,
        password2:state.user.password2
      },
    };
  },(dispatch)=>{
    return {
      saveFormFields:(fields)=>dispatch(saveFormFields(fields))
    }
  })(CollectionCreateForm);