import initialState from './initialState';
import {USER_LIST,USER_FILTER,USER_LIST_UPDATE,USER_SELECTED_ITEMS,USER_SELECTED_ITEMS_ADD,USER_TABLE_LOADING,USER_MODAL_VISIBLE,USER_FORM_REF,USER_SAVE_FORM_FIELDS,USER_MENU_KEY, USER_UPDATE,USER_CURRENT_PAGE,USER_TOTAL } from '../constants/actionTypes';
const userReducer=(state=initialState.users,action)=>{
    const {value}=action;
    const {list,selectedItems}=state;
    switch(action.type){
        case USER_LIST:
            return {...state,list:value}
        case USER_LIST_UPDATE:
            return {...state,list:[...list,...value]}
        case USER_FILTER:
            return {...state,filter:value}
        case USER_TABLE_LOADING:
            return {...state,loading:value}
        case USER_SELECTED_ITEMS:
            return {...state,selectedItems:value}
        case USER_SELECTED_ITEMS_ADD:
            return {...state,selectedItems:[...selectedItems,value]}
        case USER_MODAL_VISIBLE:
            return {...state,modalVisible:value}
        case USER_FORM_REF:
            return {...state,formRef:value}
        case USER_SAVE_FORM_FIELDS:
            return {...state,...value}
        case USER_MENU_KEY:
            return {...state,menu_key:[value]}
        case USER_UPDATE:
            return {...state,update:value,modalVisible:true}
        case USER_CURRENT_PAGE:{
            return {...state,currentPage:value}
        }
        case USER_TOTAL:{
            return {...state,total:value}
        }
        default:
            return state;
    }
}
export default userReducer;
