import { LOG_IN,UPDATE_AXIOS} from '../constants/actionTypes';
import axios from 'axios';
import initialState from './initialState';
import {api} from '../conf';
// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function basicReducer (state = initialState.basic, action) {
  // let newState;

  switch (action.type) {
    
    case LOG_IN:
      return {...state,auth:action.value};
    case UPDATE_AXIOS:
      return {...state,axios:axios.create({
        baseURL: api,
        timeout: 5000,
        headers: {'Authorization': 'Bearer ' + action.value}
      })}
    default:
      return state;
  }
}
