import React from 'react';
import {
    Form, Icon, Input, Button, Checkbox,Row,Col, Avatar,Layout
  } from 'antd';
import * as logo from '../images/logo1.png';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions/loginActions';
class NormalLoginForm extends React.Component {
    handleSubmit = (e) => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
          this.props.actions.reqLogin(this.props.axios,values);
        }
      });
    }
  
    render() {
      const { getFieldDecorator } = this.props.form;
      return (
          <Row type="flex" align="middle" justify="center"  style={{backgroundColor:"#eee",height:"100vh"}}>
              <Col span={12} xs={22} sm={16} md={12} lg={12} xl={8} style={{backgroundColor:"#fff",padding:12}}>
              <div style={{backgroundColor:"#002140",color:'white',padding:20,margin:"0 0 20px 0",display:'flex',alignItems:'center',justifyContent:'center',alignSelf:'center'}} color="#ff0000"><Avatar src={logo}/><b>ynes Admin</b> <br/></div>
                <Form onSubmit={this.handleSubmit} className="login-form">
                    <Form.Item>
                        {getFieldDecorator('userName', {
                        rules: [{ required: true, message: 'Please input your username!' }],
                        })(
                        <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username" />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('password', {
                        rules: [
                          { required: true, message: 'Please input your Password!' },
                          { min:6 , message:"Password Should be minimum six cahracter long"}
                        ],
                        })(
                        <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('remember', {
                        valuePropName: 'checked',
                        initialValue: true,
                        })(
                        <Checkbox>Remember me</Checkbox>
                        )}
                        {/* <a className="login-form-forgot" href="">Forgot password</a> */}<br/>
                        <Button type="primary" htmlType="submit" className="login-form-button" block loading={this.props.loadingBtn}>
                        Log in
                        <Icon type="login"/>
                        
                        </Button>
                        {/* Or <a href="">register now!</a> */}<br/>
                        {/* <Link to="/">Bypass Login</Link> */}
                    </Form.Item>
                </Form>
              </Col>
          </Row>
       
      );
    }
  }
  
  const Login = Form.create({
      name: 'normal_login',
      onFieldsChange(props, changedFields) {
          props.actions.saveFormFields(changedFields);
        },
      mapPropsToFields(props) {
        return {
          userName: Form.createFormField(props.formState.userName),
          password: Form.createFormField(props.formState.password)
        };
      },
      onValuesChange(_, values) {
        // console.log(values);
      }, 
  })(NormalLoginForm);
  export default connect((state) => {
    return {
      axios:state.basic.axios,
      loadingBtn:state.login.loadingBtn,
      formState: {
        userName: state.login.userName,
        password:state.login.password
      },
    };
  },(dispatch)=>{
    return {
      actions:bindActionCreators(actions,dispatch)
    }
  })(Login);