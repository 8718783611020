import React from "react";
import {bindActionCreators} from 'redux';
import * as actions from '../actions/basicActions';
import {connect} from 'react-redux';
class Logout extends React.Component{
    componentDidMount(){
      this.props.actions.login(false);
      localStorage.removeItem('adminToken');
      this.props.actions.axiosUpdate("");
    }
    render(){
      return null;
    }
  }
  const mapDispatchToProps=(dispatch)=>{
    return {actions:bindActionCreators(actions,dispatch)}
  }
    
  const mapStateToProps=state=>{
    return {
      ...state.basic
    }
  }
 export default  connect(mapStateToProps,mapDispatchToProps)(
    Logout
  )