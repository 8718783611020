import {SAVE_LOGIN_FIELDS, LOGIN_BUTTON_LOADING} from '../constants/actionTypes'
import {axiosUpdate,login} from './basicActions';
import {notification } from 'antd';
export const saveFormFields=(payload)=>({type:SAVE_LOGIN_FIELDS,payload});
export const isBtnLoading=(value)=>({type:LOGIN_BUTTON_LOADING,value})
export const reqLogin=(axios,fields)=>{
    return dispatch=>{
        dispatch(isBtnLoading(true))
        return axios.post('/login',fields)
        .then(resp=>{
            dispatch(isBtnLoading(false));
            if(resp.data.status){
                localStorage.setItem('adminToken',resp.data.token);
                dispatch(axiosUpdate(resp.data.token));
                dispatch(login(true));
                notification['success']({
                    message: 'CongratuFukiLations',
                    description: resp.data.msg,
                  });
            }else{
                notification['error']({
                    message: 'Authentication Failed',
                    description: resp.data.msg,
                  });
            }
        }).catch(err=>{
            dispatch(isBtnLoading(false));
            notification['error']({
                message: 'Connection Failed',
                description: err.message,
              });
        })
    }
}