import {SAVE_LOGIN_FIELDS,LOGIN_BUTTON_LOADING} from '../constants/actionTypes';
function loginReducer(state = {
    userName: {
      value: '',
    },
    password:{
        value:''
    },
    loadingBtn:false
  }, action) {
    switch (action.type) {
      case SAVE_LOGIN_FIELDS:
        return {
          ...state,
          ...action.payload,
        };
      case LOGIN_BUTTON_LOADING:
        return {
            ...state,
            loadingBtn:action.value
        }
      default:
        return state;
    }
  }

export default loginReducer;