export const LOG_IN="LOG_IN";
export const UPDATE_AXIOS="UPDATE_AXIOS";
export const SAVE_LOGIN_FIELDS="SAVE_LOGIN_FIELDS"
export const LOGIN_BUTTON_LOADING="LOGIN_BUTTON_LOADING"

export const USER_LIST="USER_LIST";
export const USER_LIST_UPDATE="USER_LIST_UPDATE";
export const USER_FILTER="USER_FILTER";
export const USER_TABLE_LOADING="USER_TABLE_LOADING";
export const USER_SELECTED_ITEMS="USER_SELECTED_ITEMS";
export const USER_SELECTED_ITEMS_ADD="USER_SELECTED_ITEMS_ADD";
export const USER_FORM_REF="USER_FORM_REF";
export const USER_MODAL_VISIBLE="USER_MODAL_VISIBLE";
export const USER_SAVE_FORM_FIELDS="USER_SAVE_FORM_FIELDS"
export const USER_MENU_KEY="USER_MENU_KEY"
export const USER_UPDATE="USER_UPDATE"
export const USER_CURRENT_PAGE="USER_CURRENT_PAGE"
export const USER_TOTAL="USER_TOTAL"

export const PRODUCT_LIST="PRODUCT_LIST";
export const PRODUCT_LIST_UPDATE="PRODUCT_LIST_UPDATE";
export const PRODUCT_FILTER="PRODUCT_FILTER";
export const PRODUCT_TABLE_LOADING="PRODUCT_TABLE_LOADING";
export const PRODUCT_SELECTED_ITEMS="PRODUCT_SELECTED_ITEMS";
export const PRODUCT_SELECTED_ITEMS_ADD="PRODUCT_SELECTED_ITEMS_ADD";
export const PRODUCT_FORM_REF="PRODUCT_FORM_REF";
export const PRODUCT_MODAL_VISIBLE="PRODUCT_MODAL_VISIBLE";
export const PRODUCT_SAVE_FORM_FIELDS="PRODUCT_SAVE_FORM_FIELDS"
export const PRODUCT_MENU_KEY="PRODUCT_MENU_KEY"
export const PRODUCT_UPDATE="PRODUCT_UPDATE"
export const PRODUCT_ICON_UPLOAD="PRODUCT_ICON_UPLOAD";
export const PRODUCT_CURRENT_PAGE="PRODUCT_CURRENT_PAGE"
export const PRODUCT_TOTAL="PRODUCT_TOTAL"
export const PRODUCT_SAVED_TAGS="PRODUCT_SAVED_TAGS"
export const PRODUCT_SELECTED_ID="PRODUCT_SELECTED_ID"