import {PRODUCT_LIST,PRODUCT_FILTER,PRODUCT_LIST_UPDATE,PRODUCT_SELECTED_ITEMS,PRODUCT_SELECTED_ITEMS_ADD,PRODUCT_TABLE_LOADING,PRODUCT_MODAL_VISIBLE,PRODUCT_FORM_REF,PRODUCT_SAVE_FORM_FIELDS, PRODUCT_MENU_KEY,PRODUCT_UPDATE, PRODUCT_CURRENT_PAGE, PRODUCT_TOTAL,PRODUCT_ICON_UPLOAD, PRODUCT_SAVED_TAGS,PRODUCT_SELECTED_ID } from '../constants/actionTypes';
import {notification, Avatar, Tag } from 'antd';
import {imagesDir} from '../conf'
import React from 'react';
export const setProductList=value=>{
    return {
        type:PRODUCT_LIST,
        value
    }
}
export const setFilterType=value=>{
    return {
        type:PRODUCT_FILTER,
        value
    }
}
export const updateProductList=value=>({type:PRODUCT_LIST_UPDATE,value});
export const setSelectedItem=value=>({type:PRODUCT_SELECTED_ITEMS,value});
export const addSelectedItem=value=>({type:PRODUCT_SELECTED_ITEMS_ADD,value});
export const isTableLoading=value=>({type:PRODUCT_TABLE_LOADING,value});
export const isModalVisible=value=>({type:PRODUCT_MODAL_VISIBLE,value});
export const setFormRef=value=>({type:PRODUCT_FORM_REF,value});
export const saveFormFields=(value)=>({type:PRODUCT_SAVE_FORM_FIELDS,value})
export const setMenuKey=value=>({type:PRODUCT_MENU_KEY,value})
export const update=value=>({type:PRODUCT_UPDATE,value});
export const setCurrentPage=value=>({type:PRODUCT_CURRENT_PAGE,value});
export const setTotal=value=>({type:PRODUCT_TOTAL,value});
export const iconUpload=value=>({type:PRODUCT_ICON_UPLOAD,value});
export const saveTags=value=>({type:PRODUCT_SAVED_TAGS,value});
export const selectedId=value=>({type:PRODUCT_SELECTED_ID,value});
export const loadTags=(axios)=>{
    return dispatch=>{
        return axios.get("/product/tags").then(resp=>{
            if(resp.data.status){
                dispatch(saveTags(resp.data.tags));
            }
        }
        ).catch(err=>{
            notification['error']({
                message: 'Failed to Load tags',
                description:err.message,
            });
        })
    }
}
export const uploadIcon=(axios,file,onsuccess,onerror,onprogress)=>{
    var fd=new FormData();
    fd.append('icon',file,file.name);
    return dispatch=>{
        let percent;
        return axios.post("/product/uploadIcon",fd,{
            timeout:0,
            onUploadProgress: progressEvent => {
                percent=progressEvent.loaded/progressEvent.total*100;
                onprogress({percent});
            }
        }).then(resp=>{
            if(resp.data.status){
                onsuccess(resp.data.msg);
                dispatch(iconUpload(resp.data.filename));
            }else{
                notification['error']({
                    message: 'Failed to Uplaod Icon',
                    description:resp.data.msg,
                });
                onerror(resp,resp.data.msg);
            }
        }).catch(err=>{
            notification['error']({
                message: 'Failed to Uplaod Icon',
                description:err.message,
            });
            onerror(err,err.message);
        })
    }
}
export const loadList=(axios,filter,offset,limit)=>{
    return dispatch=>{
        dispatch(isTableLoading(true));
        axios.post("/product/list",{filter,offset,limit}).then(resp=>{
            if(resp.data.status){
                var newArr=resp.data.list.map((elem,i)=>{
                    var {product_name,icon,desc,link,published,createdOn,_id,tags}=elem;
                    var newElem={createdOn,_id,link,desc};
                    newElem.tags=tags.map((v,i)=>{
                        return <Tag key={i} color="#2db7f5">{v}</Tag>
                    });
                    newElem.plainTags=tags;
                    newElem.key=elem._id;
                    newElem.icon=<Avatar src={imagesDir+icon}/>
                    newElem.published=published ? "Yes":"No";
                    newElem.name=product_name;
                    return newElem;
                  });
                dispatch(setTotal(resp.data.total));
                dispatch(setProductList(newArr));
                dispatch(isTableLoading(false));
            }else{
                dispatch(isTableLoading(false));
                notification['error']({
                    message: 'Failed to Load Products\'s list',
                    description:resp.data.msg,
                });
            }
        }
    ).catch(err=>{
        dispatch(isTableLoading(false))
        notification['error']({
            message: 'Failed to Load Products\'s list',
            description:err.message,
        });
    })
}
}
export const submitForm=(reset,axios,values)=>{
    return dispatch=>{
       return  axios.post("/product/add",values)
        .then(resp=>{
            if(resp.data.status){
                reset();
                notification['success']({
                    message: 'Product Added Succesfully',
                    description: resp.data.msg,
                });
                dispatch(loadList(axios,"",0,10));
                dispatch(setCurrentPage(1));
            }else if(resp.data.status===false){
                notification['error']({
                    message: 'Failed to Add Product',
                    description: resp.data.msg,
                });
            }else{
                notification['error']({
                    message: 'Failed to Add Product',
                    description:resp.data,
                });
            }
        }).catch(err=>{
            notification['error']({
                message: 'Failed to Add Product',
                description:err.message,
            });
        })
    }
}
export const updateProduct=(reset,axios,values,id,list)=>{
    // console.log("list",list);
    return dispatch=>{
       return  axios.post("/product/update",{...values,id})
        .then(resp=>{
            if(resp.data.status){
                reset();
                notification['success']({
                    message: 'Product Updated Succesfully',
                    description: resp.data.msg,
                });
                let newList=list.map((el,i)=>{
                    if(el._id===id){
                        var newObj={...el}
                        newObj.name=values.pname;
                        newObj.des=values.desc;
                        newObj.link=values.link;
                        newObj.plainTags=values.tags;
                        newObj.tags=values.tags.map((v,i)=>{
                            return <Tag key={i} color="#2db7f5">{v}</Tag>
                        });
                        if(values.uploadName !==""){
                            newObj.icon=<Avatar src={imagesDir+values.uploadName}/>
                        }
                        return newObj;
                    }
                    return el;
                });
                dispatch(setProductList(newList));
            }else if(resp.data.status===false){
                notification['error']({
                    message: 'Failed to Update Product',
                    description: resp.data.msg,
                });
            }else{
                notification['error']({
                    message: 'Failed to Update Product',
                    description:resp.data,
                });
            }
        }).catch(err=>{
            notification['error']({
                message: 'Failed to Update Product',
                description:err.message,
            });
        })
    }
}
export const deleteProduct=(axios,_id,list)=>(dispatch=>(
    axios.post("/product/delete",{_id}).then(resp=>{
        if(resp.data.status){
            let newList=list.filter((value)=>{
                if(value._id!==_id)
                    return value;
            });
            dispatch(setProductList(newList));
            notification['success']({
                message: 'Successfully Deleted Product',
                description: resp.data.msg,
            });
        }else if(resp.data.status===false){
            notification['error']({
                message: 'Failed to Delete Product',
                description: resp.data.msg,
            });
        }else{
            notification['error']({
                message: 'Failed to Delete Product',
                description:resp.data,
            });
        }
    }).catch(err=>{
        notification['error']({
            message: 'Failed to Delete Product',
            description:err.message,
        });
    })
));
export const publishProduct=(axios,_id,list)=>(dispatch=>(
    axios.post("/product/publish",{_id}).then(resp=>{
        if(resp.data.status){
            let newList=list.map((el,i)=>{
                if(el._id===_id){
                    return {...el,published:"Yes"}
                }
                return el;
            });
            dispatch(setProductList(newList));
            notification['success']({
                message: 'Successfully Suspended Product',
                description: resp.data.msg,
            });
        }else if(resp.data.status===false){
            notification['error']({
                message: 'Failed to Suspend Product',
                description: resp.data.msg,
            });
        }else{
            notification['error']({
                message: 'Failed to Suspend Product',
                description:resp.data,
            });
        }
    }).catch(err=>{
        notification['error']({
            message: 'Failed to Suspend Product',
            description:err.message,
        });
    })
));

export const unPublishProduct=(axios,_id,list)=>(dispatch=>(
    axios.post("/product/unpublish",{_id}).then(resp=>{
        if(resp.data.status){
            let newList=list.map((el,i)=>{
                if(el._id===_id){
                    return {...el,published:"No"}
                }
                return el;
            });
            dispatch(setProductList(newList));
            notification['success']({
                message: 'Successfully Re-activated Product',
                description: resp.data.msg,
            });
        }else if(resp.data.status===false){
            notification['error']({
                message: 'Failed to Re-activate Product',
                description: resp.data.msg,
            });
        }else{
            notification['error']({
                message: 'Failed to Re-activate Product',
                description:resp.data,
            });
        }
    }).catch(err=>{
        notification['error']({
            message: 'Failed to Re-activate Product',
            description:err.message,
        });
    })
));
export const search=(axios,value)=>{
    return dispatch=>{
        if(value===""){
            dispatch(loadList(axios,"",0,10))
        }else{
            dispatch(isTableLoading(true));
            return axios.post("/product/search",{value})
            .then(resp=>{
                dispatch(isTableLoading(false));
                if(resp.data.status){
                    var newArr=resp.data.list.map((elem,i)=>{
                        var {product_name,icon,desc,link,published,createdOn,_id,tags}=elem;
                        var newElem={createdOn,_id,link,desc};
                        newElem.tags=tags.map((v,i)=>{
                            return <Tag key={i} color="#2db7f5">{v}</Tag>
                        });
                        newElem.plainTags=tags;
                        newElem.key=elem._id;
                        newElem.icon=<Avatar src={imagesDir+icon}/>
                        newElem.published=published ? "Yes":"No";
                        newElem.name=product_name;
                        return newElem;
                      });
                    dispatch(setTotal(resp.data.total));
                    dispatch(setProductList(newArr));
                    dispatch(isTableLoading(false));
                }else{
                    dispatch(setProductList([]));
                    dispatch(isTableLoading(false));
                }
            }).catch(err=>{
                dispatch(isTableLoading(false));
                notification['error']({
                    message: 'Failed to Search Product',
                    description:err.message,
                });
            })
        }
    }
}
export const remove=(axios,values)=>{
    let _ids=values.map((v,i)=>{
        return v._id;
    })
    return dispatch=>{
        dispatch(isTableLoading(true));
        return axios.post("/product/remove",{_ids})
        .then(resp=>{
            dispatch(isTableLoading(false));
            if(resp.data.status){
                dispatch(loadList(axios,"",0,10));
                dispatch(setCurrentPage(1));
            }else{
                notification['error']({
                    message: `Failed to Remove ${_ids.length} Products`,
                    description: resp.data.msg,
                });
            }
        }).catch(err=>{
            dispatch(isTableLoading(false));
            notification['error']({
                message: 'Failed to Remove Product(s)',
                description:err.message,
            });
        })
    }
}

export const filter=(axios,filter)=>{
    return dispatch=>{
        dispatch(isTableLoading(true));
        dispatch(setFilterType(filter));
        return axios.post("/product/filter",{filter})
        .then(resp=>{
            dispatch(isTableLoading(false));
            if(resp.data.status){
                
            }else{
                
            }
        }).catch(err=>{
            dispatch(isTableLoading(false));
            notification['error']({
                message: 'Faileed to Filter Products',
                description:err.message,
            });
        })
    }
}