import initialState from './initialState';
import {PRODUCT_LIST,PRODUCT_FILTER,PRODUCT_LIST_UPDATE,PRODUCT_SELECTED_ITEMS,PRODUCT_SELECTED_ITEMS_ADD,PRODUCT_TABLE_LOADING,PRODUCT_MODAL_VISIBLE,PRODUCT_FORM_REF,PRODUCT_SAVE_FORM_FIELDS,PRODUCT_MENU_KEY, PRODUCT_UPDATE,PRODUCT_CURRENT_PAGE,PRODUCT_TOTAL,PRODUCT_ICON_UPLOAD,PRODUCT_SAVED_TAGS, PRODUCT_SELECTED_ID } from '../constants/actionTypes';
const productReducer=(state=initialState.products,action)=>{
    const {value}=action;
    const {list,selectedItems}=state;
    switch(action.type){
        case PRODUCT_LIST:
            return {...state,list:value}
        case PRODUCT_LIST_UPDATE:
            return {...state,list:[...list,...value]}
        case PRODUCT_FILTER:
            return {...state,filter:value}
        case PRODUCT_TABLE_LOADING:
            return {...state,loading:value}
        case PRODUCT_SELECTED_ITEMS:
            return {...state,selectedItems:value}
        case PRODUCT_SELECTED_ITEMS_ADD:
            return {...state,selectedItems:[...selectedItems,value]}
        case PRODUCT_MODAL_VISIBLE:
            return {...state,modalVisible:value}
        case PRODUCT_FORM_REF:
            return {...state,formRef:value}
        case PRODUCT_SAVE_FORM_FIELDS:
            return {...state,...value}
        case PRODUCT_MENU_KEY:
            return {...state,menu_key:[value]}
        case PRODUCT_UPDATE:
            return {...state,update:value,modalVisible:true}
        case PRODUCT_CURRENT_PAGE:{
            return {...state,currentPage:value}
        }
        case PRODUCT_TOTAL:{
            return {...state,total:value}
        }
        case PRODUCT_ICON_UPLOAD:
            return {...state,uploadName:{value}}
        case PRODUCT_SAVED_TAGS:{
            return {...state,savedTags:value}
        }
        case PRODUCT_SELECTED_ID:{
            return {...state,sId:value}
        }
        default:
            return state;
    }
}
export default productReducer;
