import { Layout, Menu, Breadcrumb,Icon,Avatar } from 'antd';
import React from 'react';
import { Link } from "react-router-dom";
const { Header, Content, Footer,Sider } = Layout;
import * as logo from '../images/logo1.png';
const SubMenu = Menu.SubMenu;
const keys=(path)=>{
  switch(path){
    case "/":return ['1'];
    case "/products": return ['2'];
    case "/users":return ['3','sub1'];
    case"/usersPlus":return ['4','sub1'];
    case "/nextname/users":return ['sub2','8'];
    case "/nextname/names":return ['sub2','6'];
    case "/blinddate/reports":return ['sub3','9'];
    case"/blinddate/users":return ['sub3','10'];
    default: return ['0'];
  }
};
export class Drawer extends React.Component{ 
  state = {
    collapsed: false,
    collapsedWidth:80,
    pos:'inherit',
    selectedKeys:keys(window.location.pathname)
  };

  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  }
  render(){ 
    // console.log(window.location.pathname)
    return (
  <Layout style={{ minHeight: '100vh',minWidth:'99vw' }}>
  <Sider
    style={{position:this.state.pos,zIndex:'+999'}}
    breakpoint="lg"
    collapsible
    collapsed={this.state.collapsed}
    onCollapse={this.onCollapse}
    collapsedWidth={this.state.collapsedWidth}
    // trigger={null}
    onBreakpoint={broken=>{if(broken){
      this.setState({collapsedWidth:0,pos:'fixed'})
    }else{
      this.setState({collapsedWidth:80,pos:'inherit'})
    }}}
  >
    
    <Menu theme="dark" defaultSelectedKeys={[...this.state.selectedKeys]} mode="inline" style={{
    overflow: 'auto', height: '100vh', position: 'fixed', left: 0,maxWidth:200
  }}>
      <Menu.Item key="logo" > <Avatar className="anticon" src={logo} /><span>Xynes Admin</span></Menu.Item> 
      <Menu.Item key="1">
        <Link to="/"> <Icon type="dashboard" />
        <span>Dashboard</span></Link>
      </Menu.Item>
      <Menu.Item key="2">
      <Link to="/products">
        <Icon type="desktop" />
        <span>Products</span></Link>
      </Menu.Item>
      <SubMenu
        key="sub1"
        title={<span><Icon type="user" /><span>Users</span></span>}
      >
        <Menu.Item key="3"><Link to="/users">Free Users</Link></Menu.Item>
        <Menu.Item key="4"><Link to="/usersPlus">Premium Users</Link></Menu.Item>
        {/* <Menu.Item key="5">Alex</Menu.Item> */}
      </SubMenu>
      <SubMenu
        key="sub2"
        title={<span><Icon type="appstore" /><span>Nextname</span></span>}
      >
        <Menu.Item key="6"><Link to="/nextnames/names">Manage Names</Link></Menu.Item>
        <Menu.Item key="8"><Link to="/nextnames/users">Manage Users</Link></Menu.Item>
      </SubMenu>
      <SubMenu
        key="sub3"
        title={<span><Icon type="appstore" /><span>BlindDate</span></span>}
      >
        <Menu.Item key="9"><Link to="/blinddate/reports">Abuse Reports</Link></Menu.Item>
        <Menu.Item key="10"><Link to="/blinddate/users">Manage Users</Link></Menu.Item>
      </SubMenu>
      <Menu.Item key="11" style={{marginBottom:60}}>
      <Link to="/logout"><Icon type="logout" />
        <span>Log Out</span></Link>
        
      </Menu.Item>
    </Menu>
  </Sider>
  <Layout>
  {React.cloneElement(this.props.children, {collapsed:this.state.collapsed})}
  </Layout>
</Layout>
)}
    }