import React from 'react';
import {Layout,Breadcrumb, Icon} from 'antd';
import {Link} from 'react-router-dom'
const {Header,Content,Footer}=Layout
const Dashboard=(props)=>{
    return (
        <>
        <Header style={{ background: '#fff', padding: 0,textAlign:'center' }} >Dashboard</Header>
    <Content style={{ margin: '0 16px' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item><Link to="/"><Icon type="dashboard"/> Dashboard</Link></Breadcrumb.Item>
        
      </Breadcrumb>
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        All Page information siummary will be shown here!
      </div>
    </Content>
    <Footer style={{ textAlign: 'center' }}>
      Xynes Admin ©2019 Created by Aman
    </Footer>
    </>
    )
}
export default Dashboard;