/* eslint-disable import/no-named-as-default */
import { Route, Switch,Redirect } from "react-router-dom";
import Users from "./users/users.jsx";
import Products from './products/products.jsx';
import Login from "./Login.jsx";
import NotFoundPage from "./NotFoundPage";
import React from "react";
import { hot } from "react-hot-loader";
import {Drawer} from './Drawer.jsx';
import Dashboard from './Dashboard.jsx';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from '../actions/basicActions';
import { ConnectedRouter } from 'connected-react-router';
import {bindActionCreators} from 'redux';
import Logout from './logout';
// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.
class App extends React.Component {
  static propTypes={
    auth:PropTypes.bool.isRequired,
    history:PropTypes.object.isRequired
  }
  componentDidMount(){
    this.props.axios.interceptors.response.use(undefined, (error)=> {
      if(error.response && error.response.status === 401) {
        this.props.login(false);
        error.message="Security Token Expired, Please login Again";
        return Promise.reject(error);
      }else if(error.response && error.response.status === 500){
        this.props.login(false);
        error.message="Failed to Retrive account details";
        return Promise.reject(error);
      }else{
          return Promise.reject(error);
      }
    });
  }
  render() {
    return (
      <div>
        <ConnectedRouter history={this.props.history}>
        <Switch>
          <LoginRoute  path="/log-in" auth={this.props.auth} component={Login} />
          <AdminRoute exact auth={this.props.auth} path="/" component={Dashboard} />
          <AdminRoute auth={this.props.auth} path="/users" component={Users} />
          <AdminRoute auth={this.props.auth} path="/products" component={Products} />
          <AdminRoute auth={this.props.auth} path="/logout" component={Logout} />
          {/* <Route path="/about" component={AboutPage} /> */}
          <Route component={NotFoundPage} />
        </Switch>
        </ConnectedRouter>
      </div>
      
    );
  }
}

const AdminRoute = ({ component: Component,auth, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth ? (
        <Drawer><Component {...props} /></Drawer>
      ) : (
        <Redirect
          to={{
            pathname: "/log-in",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
const LoginRoute = ({ component: Component,auth, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !auth ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const mapDispatchToProps=(dispatch)=>{
  return {actions:bindActionCreators(actions,dispatch)}
}
  
const mapStateToProps=state=>{
  return {
    ...state.basic
  }
}

// const hotConnect = (...connectArgs) =>
//   (component) =>
//     connect(...connectArgs)(hot(module)(component));
export default connect(mapStateToProps,mapDispatchToProps)(hot(module)(App));
