import React from 'react';
import {
    Button, Modal, Form, Input, Radio,Upload,Icon,message, Select
  } from 'antd';
import {connect} from 'react-redux';
import { saveFormFields,iconUpload,uploadIcon} from '../../actions/productActions';
    // eslint-disable-next-line
    const Option=Select.Option;
class XX extends React.Component {
  // async componentDidMount(){
  //   console.log("test")
  // }
  dummyRequest = ({ file, onSuccess,onError,onProgress }) => {
    this.props.uploadIcon(this.props.axios,file,onSuccess,onError,onProgress);
  };
  onUpload=(info)=> {
    let fileList = [info.file]; //...info.fileList // we want only one icon so......
    // console.log("onupload",info);
    if (info.file.status === 'uploading') {
      // this.props.iconUpload(info.file)
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      // this.props.iconUpload(info)
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
      // this.props.iconUpload(info.file)
    }
    this.props.listUpdate(fileList)
  }
  
  onRemove= (file) => {
    this.props.iconUpload("");
    const index = this.props.fileList.indexOf(file);
    const newFileList = this.props.fileList.slice();
    newFileList.splice(index, 1);
    this.props.listUpdate(newFileList)
     
    
  }
  preProcess=(file)=>{
    if(file.name.match(/\.(jpg|jpeg|png|gif)$/)){
      return true;
    }
    message.error(`${file.name} not an image`);
    return false;
  }
  normFile = info => {
    return null;
  };
    render() {
    const {Option}=Select;
    const {
        visible, onCancel, onCreate, form,
    } = this.props;
    const { getFieldDecorator } = form;
    
    return (
        <Modal
        visible={visible}
        title="Create a new Product"
        okText="Create"
        onCancel={onCancel}
        onOk={onCreate}
        centered
        bodyStyle={{height:300,overflowY:'scroll'}}
        >
        <Form layout="vertical">
            <Form.Item label="Product Name" >
            {getFieldDecorator('pname', {
                rules: [{ required: true, message: 'Please input Product Name!' }],
            })(
                <Input />
            )}
            </Form.Item>
            
            <Form.Item
              label="Upload Product Icon"
              style={{marginBottom:0}}
            >
              
                <Upload accept="image/png,image/jpeg" listType="picture" multiple={false} customRequest={this.dummyRequest} onChange={this.onUpload} beforeUpload={this.preProcess} onRemove={this.onRemove} fileList={this.props.fileList}>
                  <Button>
                    <Icon type="upload" /> Click to upload
                  </Button>
                </Upload>
              
            </Form.Item>
            <Form.Item style={{margin:0,paddingTop:0}}>
            {getFieldDecorator('uploadName', {
                rules: [{ required: !this.props.update, message: 'Please Upload an icon' }],
            })(
                <Input type="hidden"/>
            )}
            </Form.Item>
            <Form.Item label="Description">
            {getFieldDecorator('desc',{
                rules: [{ required: true, message: 'Please write a Description!' }],

            })(<Input.TextArea autosize={{ minRows: 2, maxRows: 6 }} />)}
            </Form.Item>
            <Form.Item label="Link">
            {getFieldDecorator('link',{
                rules: [{ required: true, message: 'Please enter a Link' },{type:'url',message: 'Invalid URL entered'}],

            })(<Input />)}
            </Form.Item>
            <Form.Item label="Tags">
            {getFieldDecorator('tags',{

            })(<Select mode="tags" placeholder="Tags are optional">
            {this.props.tags.map((v,i)=>{
              return (
                <Option key={i} value={v.name}>{v.name}</Option>
              )
            })}
            </Select>)}
            </Form.Item>
           
        </Form>
        </Modal>
    );
    }
}
const CollectionCreateForm = Form.create({
    name: 'add_users',
    onFieldsChange(props, changedFields) {
        props.saveFormFields(changedFields);
      },
    mapPropsToFields(props) {
      return {
        pname: Form.createFormField(props.formState.pname),
        desc: Form.createFormField(props.formState.desc),
        link:Form.createFormField(props.formState.link),
        uploadName:Form.createFormField(props.formState.uploadName),
        tags:Form.createFormField(props.formState.tags),
      };
    },
    onValuesChange(_, values) {
      // console.log("proudcts",values);
    }, 
})(XX)
export default connect((state) => {
    return {
      tags:state.product.savedTags,
      formState: {
        pname: state.product.pname,
        desc:state.product.desc,
        link:state.product.link,
        uploadName:state.product.uploadName,
        tags:state.product.tags
      },
    };
  },(dispatch)=>{
    return {
      saveFormFields:(fields)=>dispatch(saveFormFields(fields)),
      iconUpload:(upload)=>dispatch(iconUpload(upload)),
      uploadIcon:(a,b,c,d,e)=>dispatch(uploadIcon(a,b,c,d,e))
    }
  })(CollectionCreateForm);